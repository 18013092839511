import { SectionComponents, Blocks } from '~/types/components'

export default function () {
  const resolveSectionComponent = (block: SectionComponents) => {
    if (!block) {
      return
    }

    const componentName = Object.entries(SectionComponents).find(([key, _]) => key === block)?.[1]

    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error(`Component for model type [${block}] does not exist.`)
    } else {
      return resolveComponent(componentName)
    }
  }

  const resolveBlockComponent = (block: Blocks) => {
    if (!block) {
      return
    }

    const componentName = Object.entries(Blocks).find(([key, _]) => key === block)?.[1]

    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error(`Block Component for model type [${block}] does not exist.`)
    } else {
      return resolveComponent(componentName)
    }
  }

  return {
    resolveSectionComponent,
    resolveBlockComponent
  }
}
