// https://github.com/nuxt/framework/issues/6711

export enum SectionComponents {
  SectionArticleListRecord = 'SectionArticles',
  SectionProjectListRecord = 'SectionArticles',
  SectionBodyRecord = 'SectionBody',
  SectionContactFormRecord = 'SectionContactForm',
  SectionCtaRecord = 'SectionCta',
  SectionFeaturesListRecord = 'SectionFeatures',
  SectionTitleRecord = 'SectionTitle',
  SectionHeroRecord = 'SectionHero',
  SectionImageGalleryRecord = 'SectionImageGallery',
  SectionImageGalleryTwoRecord = 'SectionImageGalleryTwo',
  SectionLogosListRecord = 'SectionLogos',
  SectionMediaBlockRecord = 'SectionMedia',
  SectionRelatedArticleRecord = 'SectionRelatedArticles',
  SectionTestimonialsListRecord = 'SectionTestimonials',
  SectionVideoRecord = 'SectionVideo',
  SectionTextBlockRecord = 'SectionText',
  SectionPeopleBlockRecord = 'SectionPeople',
  SectionItemDetailRecord = 'SectionItemDetail',
  SectionHeroItemRecord = 'SectionHeroItem',
  SectionHeroSimpleRecord = 'SectionHeroSimple',
  SectionSplitRecord = 'SectionSplit',
  SectionSplit1Record = 'SectionSplitOne',
  SectionSplit2Record = 'SectionSplitTwo',
  SectionSplit3Record = 'SectionSplitThree',
  SectionSplitTitleRecord = 'SectionSplitTitle',
  SectionHighlightRecord = 'SectionHighlights',
  SectionWorldwideRecord = 'SectionWorldwide',
  SectionServiceRecord = 'SectionServices',
  SectionHeroB1Record = 'SectionHeroB1',
  SectionLocationRecord = 'SectionLocations',
  SectionTitle2Record = 'SectionTitleTwo',
  SectionMultipleRecord = 'SectionMultiple',
  SectionClientNumberListRecord = 'SectionClientNumbers',
  SectionClientListRecord = 'SectionClients',
  SectionRoadmapRecord = 'SectionRoadmap',
  SectionJobListingRecord = 'SectionJobs'
}

export enum Blocks {
  LottieRecord = 'Lootie'
}
